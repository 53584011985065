import React, {useState, useEffect} from "react";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import { theme_dark, theme_light } from "./theme";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


import NotFoundScreen from "./screens/404";

import Warteliste from "./screens/Warteliste";
import WartelisteMailBestätigung from "./screens/WartelisteMailBestätigung";
import WartelisteBenutzerEntfernen from "./screens/WartelisteBenutzerEntfernen";

const App = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {

    const matchDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
    setDarkMode(matchDarkMode.matches);

    const handleThemeChange = (e) => setDarkMode(e.matches);
    matchDarkMode.addEventListener('change', handleThemeChange);

    return () => matchDarkMode.removeEventListener('change', handleThemeChange);

  }, []);

  const GlobalStyle = createGlobalStyle`
    *::-webkit-scrollbar {
      width: 8px;
    }

    *::-webkit-scrollbar-track {
      background: ${props => props.theme.scrollbar.track_color};
    }

    *::-webkit-scrollbar-thumb {
      background: ${props => props.theme.scrollbar.thumb_color};
      border-radius: 10px;
    }
  `;


  return (
    <ThemeProvider theme={darkMode ? theme_dark : theme_light}>
    <GlobalStyle theme={darkMode ? theme_dark : theme_light} />
        <Router>
          <Routes>
            <Route path="/warteliste" element={<Warteliste />} />
            <Route path="/waitlist" element={<Warteliste />} />
            <Route path="/waitlist/embedded" element={<Warteliste embedded/>} />
            <Route
              path="/warteliste/verify/:token"
              element={<WartelisteMailBestätigung />}
            />
            <Route
              path="/waitlist/verify/:token"
              element={<WartelisteMailBestätigung />}
            />
            <Route
              path="/warteliste/remove/:token"
              element={<WartelisteBenutzerEntfernen />}
            />
            <Route
              path="/waitlist/remove/:token"
              element={<WartelisteBenutzerEntfernen />}
            />

            <Route path="*" element={<NotFoundScreen />} />
          </Routes>
        </Router>
      <GlobalStyle />
    </ThemeProvider>
  );
};

export default App;
