const primary_color_light = "#9DBF9E";
const primary_color_dark = "#617762";

export const theme_dark = {
  state: "dark",
  background_color: "#101010",
  title_color: "#FFFFFF",
  description_color: "#EEEEEE",
  divider_color: "#3F3F3F",
  primary_color: primary_color_dark,
  scrollbar: {
    thumb_color: "#4F4F4F",
    track_color: "#101010",
  },
  textfield: {
    background_color: "#202020",
    border_color: "#636363",
    label_color: "#0056FF",
    text_color: "#EEEEEE",
    title_color: "#EEEEEE",
    description_color: "#AFAFAF",
    validation: {
      valid_color: "#61A561",
      invalid_color: "#D25858",
    },
  },
  slider: {
    background_color: "#202020",
    thumb_color: "#EEEEEE",
  },
  selectionItem: {
    background_color_notSelected: "transparent",
    border_color_notSelected: "#606060",
    background_color_selected: "rgba(157, 191, 158, 0.1)",
    border_color_selected: "#9DBF9E",
    title_color: "#EEEEEE",
  },
  checkbox: {
    background_color: "#202020",
    background_color_hover: "#202020",
    border_color: "#636363",
    checkmark_color: "#EEEEEE",
    text_color: "#EEEEEE",
  },
  page: {
    filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.05));",
  },
  buttons_new: {
    dominant: {
      background_color: primary_color_dark,
      background_color_hover: "#DC323D",
      text_color: "#EEEEEE",
    },
  },
  buttons: {
    standard: {
      background: "#292929",
      hover: "#252525",
      text: "#EEEEEE",
    },
    onWhite: {
      background: "#292929",
      hover: "#252525",
      text: "#EEEEEE",
    },
    outline: {
      border: "#2E2E2E",
      hover: "#292929",
      text: "#EEEEEE",
    },
    ghost: {
      hover: "#292929",
      text: "#EEEEEE",
    },
    dominant: {
      background: "#FF3A47",
      hover: "#DC323D",
      text: "#EEEEEE",
    },
  },
  popup: {
    pageOverlayColor: "rgba(0, 0, 0, 0.4)",
    border: "#555555",
    background: "rgba(19, 19, 19, 1.0)",
    text: "#fff",
    sidebar: {
      background: "#191919",
      selectedElement: "#1F1F1F",
      text: "#CFCFCF",
      icon: "#CFCFCF",
      hambugerBackground: "#000000",
    },
  },
};

export const theme_light = {
  state: "light",
  background_color: "#FAFAFA",
  title_color: "#000000",
  description_color: "#191919",
  divider_color: "#D9D9D9",
  primary_color: primary_color_light,
  scrollbar: {
    thumb_color: "#B3B3B3",
    track_color: "#FAFAFA",
  },
  textfield: {
    background_color: "#F1F1F1",
    border_color: "transparent",
    label_color: "#0056FF",
    title_color: "#191919",
    text_color: "#050505",
    description_color: "#7F7F7F",
    validation: {
      valid_color: "#61A561",
      invalid_color: "#D25858",
    },
  },
  slider: {
    background_color: "#DADADA",
    thumb_color: "#414141",
    thumb_color_hover: "#191919",
  },
  selectionItem: {
    background_color_notSelected: "#F1F1F1",
    border_color_notSelected: "#CECECE",
    background_color_selected: "rgba(157, 191, 158, 0.1)",
    border_color_selected: "#9DBF9E",
    title_color: "#000000",
  },
  checkbox: {
    background_color: "#DFDFDF",
    background_color_hover: "#CECECE",
    border_color: "#636363",
    checkmark_color: "#000",
    text_color: "#484848",
  },
  page: {
    filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.03));",
  },
  buttons_new: {
    dominant: {
      background_color: primary_color_light,
      background_color_hover: "#DC323D",
      text_color: "#000000",
    },
  },
  buttons: {
    standard: {
      background: "#fff",
      hover: "#CACACA",
      text: "#000",
    },
    onWhite: {
      background: "#E6E6E6",
      hover: "#D6D6D6",
      text: "#000",
    },
    outline: {
      border: "#CACACA",
      hover: "#F1F1F1",
      text: "#000",
    },
    ghost: {
      hover: "#F1F1F1",
      text: "#000",
    },
    dominant: {
      background: "#FF3A47",
      hover: "#DC323D",
      text: "#EEEEEE",
    },
  },
  popup: {
    pageOverlayColor: "rgba(0, 0, 0, 0.4)",
    background: "rgba(255, 255, 255, 1.0)",
    border: "#CACACA",
    text: "#252525",
    sidebar: {
      background: "#FAFAFA",
      selectedElement: "#EEEEEE",
      text: "#252525",
      icon: "#252525",
      hambugerBackground: "#FAFAFA",
    },
  },
};
