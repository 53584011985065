import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const WartelisteBenutzerEntfernen = () => {
  //get token from url
  const url = window.location.href;
  const token = url.split("/").pop();

  const [status, setStatus] = useState("loading");

  const httpAdress =
    "https://deletewaitlistuser-i3x3hibd6a-ew.a.run.app";

  useEffect(() => {
    fetch(httpAdress, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: token }),
    }).then((response) => {
      if (response.status === 200) {
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  }, []);

  return (
    <MailBestätigungsWrapper>
      {status === "loading" && <p>loading...</p>}
      {status === "success" && <p>Deine Daten wurden erflogreich gelöscht</p>}
      {status === "error" && <p>Es ist ein Fehler aufgetreten</p>}
    </MailBestätigungsWrapper>
  );
};

export default WartelisteBenutzerEntfernen;

const MailBestätigungsWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;

  overflow-y: auto;
  overflow-x: hidden;

  background-color: ${(props) => props.theme.background_color};
  color: ${(props) => props.theme.title_color};

  display: flex;
  flex-direction: column;
  align-items: center;
`;
