import React, { forwardRef, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Check, X } from "lucide-react";

/*
  liveValidation function should return a json object with the following structure:

  {
    valid: true/false,
    validationSteps: [
      {
        label: "more than 6 characters",
        valid: true/false
      },
      {
        label: "contains special characters",
        valid: true/false
      }
    ]
  }
*/

const Textfield = forwardRef(
  (
    {
      label = "",
      required = false,
      validationFunction = () => {},
      liveValidationFunction = () => {},
      description = "",
      error = "",
      type = "text",
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [validationSteps, setValidationSteps] = useState([]);

    var currentValue = "";

    const onChange = (e) => {
      const value = e.target.value;
      currentValue = value;
      if (liveValidationFunction) {
        const liveValidation = liveValidationFunction(value);
        setIsValid(liveValidation.valid);
        setValidationSteps(liveValidation.validationSteps);
      }
    };

    return (
      <TextfieldWrapper ref={ref}>
        <Textinput
          placeholder={label}
          onChange={onChange}
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => setIsFocused(false)}
          spellcheck="false"
          autoCapitalize="off"
          type={type}
        />

        {error && <Error>{error}</Error>}

        <Description>{description}</Description>

        {validationSteps.length > 0 && isFocused && (
          <motion.div
            initial={{ height: "0px" }}
            animate={{ height: 18 * validationSteps.length + "px" }}
            transition={{ duration: 0.2 }}
            style={{
              overflow: "hidden",
              width: "100%",
              marginTop: "10px",
            }}
          >
            {validationSteps.map((step, index) => {
              return <ValidationStepItem key={index} step={step} />;
            })}
          </motion.div>
        )}
      </TextfieldWrapper>
    );
  },
);

export default Textfield;

const TextfieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
  min-width: 300px;
`;

const Textinput = styled.input.attrs((props) => ({
  placeholderTextColor: props.theme.textfield.label_color,
}))`
  height: 45px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.textfield.background_color};

  padding-left: 10px;

  border: solid 1px ${(props) => props.theme.textfield.border_color};

  color: ${(props) => props.theme.textfield.text_color};
  opacity: 1;
  font-family: "Heebo", sans-serif;
  font-size: 15px;
  font-size: 0.85rem;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  &:focus {
    outline: none;
  }
`;

const Description = styled.p`
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
  color: ${(props) => props.theme.textfield.description_color};
  font-size: 14px;
  font-size: 0.85rem;
  margin-top: 10px;
  margin-left: 5px;
`;

const Error = styled(Description)`
  color: ${(props) => props.theme.textfield.validation.invalid_color};
`;

const ValidationStepItem = ({ step }) => {
  const valid = step.valid;
  const label = step.label;

  return (
    <ValidationStepItemWrapper>
      {valid ? (
        <ValidStepIcon valid={valid} />
      ) : (
        <InvalidStepIcon valid={valid} />
      )}
      <ValidationStepLabel valid={valid}>{label}</ValidationStepLabel>
    </ValidationStepItemWrapper>
  );
};

const ValidationStepItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ValidationStepLabel = styled.p`
  font-size: 14px;
  font-size: 0.85rem;
  margin-left: 5px;
  color: ${(props) =>
    props.valid
      ? props.theme.textfield.validation.valid_color
      : props.theme.textfield.validation.invalid_color};
`;

const ValidStepIcon = styled(Check)`
  height: 14px;
  width: 14px;
  color: ${(props) => props.theme.textfield.validation.valid_color};
`;

const InvalidStepIcon = styled(X)`
  height: 14px;
  width: 14px;
  color: ${(props) => props.theme.textfield.validation.invalid_color};
`;
