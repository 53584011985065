import styled from "styled-components";

const NotFoundScreen = () => {

  return (
  <NotFoundWrapper>
    <ErrorCodeText>404</ErrorCodeText>
    <VerticalDivider />
    <ErrorText>Page not found</ErrorText>
  </NotFoundWrapper>
  )
}

export default NotFoundScreen;

const NotFoundWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.background_color};
  color: ${(props) => props.theme.title_color};
`;

const ErrorCodeText = styled.p`
  font-size: 1.45rem;
  font-weight: 600;
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${(props) => props.theme.title_color};
  margin: 0 20px;
`;

const ErrorText = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
`;
