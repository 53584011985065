import React, { useState, forwardRef } from "react";
import styled from "styled-components";
import * as CheckboxRadix from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";

const Checkbox = forwardRef(
  (
    {
      label = "click to select",
      description = "",
      defaultOption = false,
      onChange = () => {},
      error = "",
      children,
    },
    ref,
  ) => {
    const [isSet, _setIsSet] = useState(defaultOption);

    const setIsSet = (value) => {
      _setIsSet(value);
      onChange(value);
    };

    return (
      <>
        <CheckboxWrapper ref={ref}>
          <CheckboxButton
            onClick={() => {
              setIsSet(!isSet);
            }}
          >
            {isSet && <CheckedIcon />}
          </CheckboxButton>
          <CheckboxText>{label}</CheckboxText>
        </CheckboxWrapper>

        {error && <Error>{error}</Error>}

        <Description>
          {description}
          {children}
        </Description>
      </>
    );
  },
);

export default Checkbox;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;

const CheckboxButton = styled.button`
  all: unset;
  -webkit-tap-highlight-color: transparent;
  background-color: white;
  width: 23px;
  height: 23px;
  min-width: 23px;
  min-height: 23px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px var(--black-a7);
  background-color: ${(props) => props.theme.checkbox.background_color};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.checkbox.background_color_hover};
  }

  transition: background-color 0.2s;

  margin-bottom: 5px;
`;

const CheckboxText = styled.label`
  color: ${(props) => props.theme.checkbox.text_color};
  font-size: 14px;
  font-size: 0.87rem;
  font-weight: normal;
  margin-left: 10px;
`;

const CheckedIcon = styled(Check)`
  height: 16px;
  width: 16px;
  color: ${(props) => props.theme.checkbox.checkmark_color};
`;

const Description = styled.p`
  width: 80%;
  color: ${(props) => props.theme.textfield.description_color};
  font-size: 14px;
  font-size: 0.85rem;
  margin-top: 10px;
`;

const Error = styled(Description)`
  color: ${(props) => props.theme.textfield.validation.invalid_color};
`;
