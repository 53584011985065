import React, { useState, forwardRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Waitlistslider = forwardRef(
  (
    {
      label = "Slide",
      labelAddition = "",
      description = "",
      unit = "€",
      icon = "",
      min = 0,
      max = 100,
      step = 1,
      onChange = () => {},
      children,
    },
    ref,
  ) => {
    const [value, _setValue] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    const setValue = (newValue) => {
      _setValue(newValue);
      onChange(newValue);
    };

    const scale = 1 + (value - min) / (max - min);
    const positionPercent = ((value - min) / (max - min)) * 98;

    return (
      <WaitlistsliderWrapper ref={ref}>
        <Title>
          {label}{" "}
          <TitleAddition>{labelAddition}</TitleAddition>
        </Title>
        <CurrentValue>
          {value} {unit}
        </CurrentValue>

        <SliderContainer>
          <TrackedIcon
            style={{
              left: `calc(${positionPercent}% - 10px)`,
              bottom: `calc((10px - ${positionPercent} * 0.1px) - 50px)`,
            }}
            animate={{ scale, opacity: isDragging ? 1 : 0 }}
            transition={{ duration: 0.1 }}
          >
            {icon}
          </TrackedIcon>

          <SliderDragagble
            type="range"
            min={min}
            max={max}
            step={step}
            onChange={(e) => setValue(Number(e.target.value))}
            value={value}
            onMouseDown={() => setIsDragging(true)}
            onMouseUp={() => setIsDragging(false)}
            onTouchStart={() => setIsDragging(true)}
            onTouchEnd={() => setIsDragging(false)}
          />
        </SliderContainer>

        <Description>
          {description}
          {children}
        </Description>
      </WaitlistsliderWrapper>
    );
  },
);

export default Waitlistslider;

const WaitlistsliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
  border-radius: 10px;
  min-width: 300px;

  color: ${(props) => props.theme.textfield.text_color};
`;

const Title = styled.p`
  width: 80%;
  @media (max-width: 768px) {
    width: 100%;
  }
  color: ${(props) => props.theme.textfield.title_color};
  font-family: "Inter", sans-serif;
  font-size: 17px;
  font-size: 1.06rem;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const TitleAddition = styled.span`
  color: ${(props) => props.theme.textfield.title_color};
  font-size: 16px;
  font-size: 0.95rem;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
`;

const Description = styled.p`
  width: 80%;
  @media (max-width: 768px) {
    width: 100%;
  }
  color: ${(props) => props.theme.textfield.description_color};
  font-size: 14px;
  font-size: 0.85rem;
  margin-top: 10px;
`;

const CurrentValue = styled.p`
  font-size: 17px;
  font-weight: standard;
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const TrackedIcon = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: -50px;
  white-space: nowrap;
  margin-left: 10px;
`;

// Styled slider input
const SliderDragagble = styled(motion.input)`
  appearance: none;
  height: 10px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  background-color: ${(props) => props.theme.slider.background_color};

  &::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.slider.thumb_color};


  }

  &::-moz-range-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.slider.thumb_color};
  }
`;
