import React, { useState, forwardRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import Textfield from "./Textfield";

const Waitlistoptionslector = forwardRef(
  (
    {
      label = "Select an option",
      description = "",
      options = [],
      defaultOption = 0,
      onChange = () => {},
      children,
    },
    ref,
  ) => {
    const [selectedItem, _setSelectedItem] = useState(defaultOption);
    const [userInput, setUserInput] = useState("");

    const setSelectedItem = (item) => {
      _setSelectedItem(item);

      const returnObject = {
        value: options[item].value,
        userText: options[item].userText === true ? userInput : null,
      };
      onChange(returnObject);
    };

    const onUserInput = (e) => {
      setUserInput(e.target.value);

      const returnObject = {
        value: options[selectedItem].value,
        userText:
          options[selectedItem].userText === true ? e.target.value : null,
      };
      onChange(returnObject);
    };

    return (
      <WaitlistsliderWrapper ref={ref}>
        <Title>{label}</Title>
        <Description>
          {description}
          {children}
        </Description>

        <SelectableOptionsWrapper>
          {options.map((option, index) => {
            return (
              <WaitlistOptionWrapper
                selected={selectedItem == index}
                onClick={() => setSelectedItem(index)}
              >
                <OptionTitle>{option.label}</OptionTitle>
              </WaitlistOptionWrapper>
            );
          })}
        </SelectableOptionsWrapper>

        <motion.div
          style={{
            overflow: "hidden",
          }}
          initial={{ height: 0 }}
          animate={{ height: options[selectedItem].userText === true ? 65 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <Textinput
            placeholder="am liebsten im Bereich (optional)"
            onChange={(e) => onUserInput(e)}
          />
        </motion.div>
      </WaitlistsliderWrapper>
    );
  },
);

export default Waitlistoptionslector;

const WaitlistsliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
  border-radius: 10px;
  min-width: 300px;

  color: ${(props) => props.theme.textfield.text_color};
`;

const Title = styled.p`
  width: 80%;
  @media (max-width: 768px) {
    width: 100%;
  }
  color: ${(props) => props.theme.textfield.title_color};
  font-size: 17px;
  font-size: 1.06rem;
  font-weight: 600;
  margin-top: 10px;
  font-family: "Inter", sans-serif;
`;

const Description = styled.p`
  width: 80%;
  @media (max-width: 768px) {
    width: 100%;
  }
  color: ${(props) => props.theme.textfield.description_color};
  font-size: 14px;
  font-size: 0.85rem;
  margin-top: 10px;
`;

const SelectableOptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  gap: 13px;
  margin-top: 25px;
`;

const WaitlistOptionWrapper = styled(motion.button)`
  remove: all;
  width: fit-content;
  min-width: 110px;
  min-height: 60px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 13px;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  -webkit-tap-highlight-color: transparent;

  background-color: ${(props) =>
    props.selected
      ? props.theme.selectionItem.background_color_selected
      : props.theme.selectionItem.background_color_notSelected};

  border: solid 1px
    ${(props) =>
      props.selected
        ? props.theme.selectionItem.border_color_selected
        : props.theme.selectionItem.border_color_notSelected};

  transition:
    background-color 0.2s,
    border-color 0.2s;
`;

const OptionTitle = styled.p`
  display: inline;
  color: ${(props) => props.theme.selectionItem.title_color};
  font-size: 13.5px;
  font-size: 0.83rem;
  font-weight: 500;
`;

const Textinput = styled.input.attrs((props) => ({
  placeholderTextColor: props.theme.textfield.label_color,
}))`
  margin-top: 20px;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.textfield.background_color};

  padding-left: 10px;

  border: solid 1px ${(props) => props.theme.textfield.border_color};

  color: ${(props) => props.theme.textfield.text_color};
  opacity: 1;
  font-family: "Heebo", sans-serif;
  font-size: 15px;
  font-size: 0.85rem;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  &:focus {
    outline: none;
  }
`;
